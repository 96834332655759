const init = {
    status:false,
    message:'',
    data:{}
}

export const getNotificationReducer = (state = init, action) => {
    switch (action.type){
        case 'GET_NOTIFICATION_START':
            return state;
        case 'GET_NOTIFICATION_FAIL':
            return {...state, message:action.payload};
        case 'GET_NOTIFICATION_SUCCESS':
            return {...state, status:true, data:action.payload};
        default:
            return state;
    }
};