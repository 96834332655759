import React from 'react';

const Password = ({name,placeholder,id,errors,value, handleChange}) => {

    return (
        <div className="input-group wow fadeInUp" data-wow-delay="1.8s">
            <input
                name={name}
                type="password"
                id={id}
                className="form-control"
                placeholder={placeholder}
                onChange={handleChange}
                value={value}
            />
            <div
                id={`${id}-error`}
                className="invalid-feedback animated fadeInUp p-0"
                style={{ display: "block" }}
            >
                {errors && errors}
            </div>
        </div>
    )

}

export default Password