import React, { useRef, useState } from "react";
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper";
import { IMAGES } from "../constants/theme";

const sliderDate = [
    {bgimage: IMAGES.BlogSlider1, number: '20', image: IMAGES.Avatar1, title: 'Five Things To Avoid In Cryptocurrency.'},
    {bgimage: IMAGES.BlogSlider2, number: '07', image: IMAGES.Avatar2, title: 'Things That Make You Love Cryptocurrency.'},
    {bgimage: IMAGES.BlogSlider3, number: '24', image: IMAGES.Avatar3, title: 'Directly support individuals Crypto'},
    {bgimage: IMAGES.BlogSlider1, number: '20', image: IMAGES.Avatar1, title: 'Five Things To Avoid In Cryptocurrency.'},
];

export default function LatestNewsSlider() {
  return (
    <>
      <Swiper 
        navigation={true} 
        modules={[Navigation]} 
        speed={1500}
        spaceBetween={30}
        slidesPerView={3}
        loop={true}
        breakpoints= {{
            1200: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 2,
            },
            320: {
                slidesPerView: 1,
            }}
        }
        className="swiper recent-blog1"        
    >
        {sliderDate.map((item, ind)=>(
            <SwiperSlide key={ind}>
                <div className="dz-card style-1 wow fadeInUp" data-wow-delay="0.6s" style={{backgroundImage:"url("+item.bgimage +")"}}> 
                    <div className="dz-info">
                        <div className="dz-meta">
                            <ul>
                                <li className="post-author">
                                    <img src={item.image} alt="" /> By Jone Doe
                                </li>
                                <li className="post-comments">{item.number} Comments</li>
                                <li className="post-date">
                                    <span className="day">{item.number}</span>
                                    <span className="month">January</span>
                                </li>
                            </ul>
                        </div>
                        <h5 className="dz-title"><Link to={"/blog-details"}>{item.title}</Link></h5> 
                        <p>Nostrud tem exrcitation duis laboris nisi ut aliquip sed duis aute.</p>
                        <Link to={"/blog-details"} className="btn btn-primary">Read More</Link>
                    </div>
                </div>
            </SwiperSlide>        
        ))}
      </Swiper>
    </>
  );
}