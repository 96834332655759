import API from '../../Api';

export const NotificationStart = () => ({
    type:"GET_NOTIFICATION_START"
})

export const NotificationSuccess = payload => ({
    type:"GET_NOTIFICATION_SUCCESS",
    payload
})

export const NotificationFail = payload => ({
    type:"GET_NOTIFICATION_FAIL",
    payload
})

export const  NotificationAsync = (data) => async dispatch => {
    dispatch(NotificationStart())
    try {
        API.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
        const resp = await API.post('notification',data);
        const aData = resp.data
        if(aData.status){
            dispatch(NotificationSuccess(aData.data))
        }
        else
            dispatch(NotificationFail(aData.message))

    }catch (e){
        dispatch(NotificationFail(e.message))
    }
};