const init = {}
export const fetchCountryReducer = (state=init, action) => {
    switch(action.type){
        case 'FETCH_COUNTRIES_START':
            return state;
        case 'FETCH_COUNTRIES_SUCCESS':
            return {...state, data:action.payload};
        case 'FETCH_COUNTRIES_ERROR':
            return state;
        default:
            return state;
    }
};