import React, { useState, useRef, useEffect } from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { motion, useScroll } from "framer-motion";

import { IMAGES, SVGICON } from '../constants/theme';
import {connect} from "react-redux";

const cryotoCard = [
    {image: IMAGES.coin7, title: 'Bitcoin', subtitle: 'BTC', price: '34,678.50', percent: '5.59'},
    {image: IMAGES.coin3, title: 'Ethereum', subtitle: 'ETH', price: '1,845.32', percent: '9.86'},
    {image: IMAGES.coin8, title: 'Tether', subtitle: 'USDT', price: '1.000393', percent: '-0.02'},
];

const iconWrapper = [
    {id:"1", title: 'Lower fees & no hidden costs', image: IMAGES.Doublehand,},
    {id:"2", title: 'We speak your language', image: IMAGES.World,},
    {id:"3", title: 'Simple gateway to crypto', image: IMAGES.ArrowCoin,}
];

const Home = (props) => {
    const [onHover, setOnHover] = useState('2');
    const ref = useRef(null);
    const {loginData} = props;
    const navigate = useNavigate();

    const { scrollYProgress } = useScroll({
      target: ref,
      offset: ["end end", "start start"]
    });

    const handleInvets = () => {
        console.log("1",loginData.isLogin)
        if(loginData.isLogin){
            console.log("2",loginData.isLogin)
            window.location.replace("/dashboard");
        }else{
            console.log("3",loginData.isLogin)
            window.location.replace("/login");

        }
    }
    return(
        <>
            <div className="page-content">		
                <div className="main-bnr">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-7 col-md-6">                               
                                <h1 className="wow fadeInUp" data-wow-delay="0.2s">The <span className="text-line">Easy Way To Investing</span> <br/>With Low Risk </h1>
                                <div className="contant-box style-1 wow fadeInUp" data-wow-delay="0.6s">
                                    <Link to={"#"} onClick={handleInvets} className="btn btn-lg  btn-shadow btn-primary m-r30">START Investing</Link>
                                </div>
                            </div>
                            <div className="col-xl-5 col-md-6">
                                <div className="banner-media">
                                    <img className="media  wow bounceInRight center" data-wow-delay="0.8s"  src={IMAGES.Digital} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="shape1" src={IMAGES.BannerShape1} alt="" />
                </div>
                <div className="crypto-wrapper bg-light overflow-hidden">
                    <div className="container">
                        <div className="row">
                            {cryotoCard.map((data, index)=>(
                                <div className="col-lg-4 m-b30" key={index}>
                                    <div className="crypto-box wow fadeInUp" data-wow-delay="1.0s">
                                        <div className="crypto-media">
                                            <img src={data.image} alt="" />
                                        </div>
                                        <div className="crypto-info">
                                            <h5>{data.title} <span>{data.subtitle}</span></h5>
                                            <div className="price">${data.price} <span className={`${index=== 1 ? 'text-red' : 'text-green'}`}>{data.percent}%</span></div>
                                            <Link to={"#"} className="btn btn-square btn-primary " target="blank">
                                                {SVGICON.Arrow}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>   
                    </div>   
                </div>   
                <section className="content-inner coins-wrapper bg-light overflow-hidden">
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-6 m-b30">
                                <div className="section-head wow fadeInUp" data-wow-delay="0.2s">
                                    <h2 className="title">With <span className="text-primary">FutureFX</span><br/> start investing easily</h2>
                                </div>
                                <h5 className="sub-title text-primary wow fadeInUp" data-wow-delay="0.4s" >Get started in a few minutes</h5>
                                <ul className="list-style-1">
                                    <li className="wow fadeInUp" data-wow-delay="0.6s">Create and verify your account</li>
                                    <li className="wow fadeInUp" data-wow-delay="0.8s">Transfer funds from your (TRC20) Wallet</li>
                                    <li className="wow fadeInUp" data-wow-delay="1.0s">Start your investment journey so easily</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 m-b30">
                                <div className="coins-media-wrapper"  ref={ref}>
                                    <div className="main-circle1">
                                        <motion.div className="circle-box" 
                                            initial={{ scale: 0,rotate:'0deg' }}
                                            whileInView={{ scale: 1,rotate:'360deg' }}
                                            //data-350="transform:scale(0) rotate(0deg);" data-550="transform:scale(1) rotate(360deg);"
                                        ></motion.div>
                                        <ul className="coin-list">
                                            <motion.li  className="skrollable skrollable-after"                                               
                                                transition={{
                                                    ease: "linear",
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: "50%", top:"50%" }}
                                                whileInView={{ scale: 1, left: "1.5%", top:"50%" }}
                                            >
                                                <img src={IMAGES.LogoIcon8} alt="" />                                                
                                            </motion.li>
                                            <motion.li                                                 
                                                transition={{
                                                    ease: "linear",
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: "50%", top:"50%" }}
                                                whileInView={{ scale: 1, left: "16%", top:"16%" }}
                                            >
                                                <img src={IMAGES.LogoIcon2} alt="" />                                                
                                            </motion.li>
                                            <motion.li                                                 
                                                transition={{
                                                    ease: "linear",
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: "50%", top:"50%" }}
                                                whileInView={{ scale: 1, left: "50%", top:"1.5%" }}                                                
                                            >
                                                <img src={IMAGES.LogoIcon3} alt="" />                                                
                                            </motion.li>
                                            <motion.li                                                 
                                                transition={{
                                                    ease: "linear",
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: "50%", top:"50%" }}
                                                whileInView={{ scale: 1, left: "84%", top:"16%" }}
                                            >
                                                <img src={IMAGES.LogoIcon4} alt="" />                                                
                                            </motion.li>
                                            <motion.li                                                 
                                                transition={{
                                                    ease: "linear",
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: "50%", top:"50%" }}
                                                whileInView={{ scale: 1, left: "98%", top:"50%" }}
                                            >
                                                <img src={IMAGES.LogoIcon5} alt="" />                                                
                                            </motion.li>
                                            <motion.li                                                
                                                transition={{
                                                    ease: "linear",
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: "50%", top:"50%" }}
                                                whileInView={{ scale: 1, left: "84%", top:"84%" }}
                                            >
                                                <img src={IMAGES.LogoIcon1} alt="" />                                                
                                            </motion.li>
                                            <motion.li                                             
                                                transition={{
                                                    ease: "linear",
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: "50%", top:"50%" }}
                                                whileInView={{ scale: 1, left: "50%", top:"98%" }}
                                            >

                                                <img src={IMAGES.LogoIcon6} alt="" />                                                
                                            </motion.li>
                                            <motion.li                                                 
                                                transition={{
                                                    ease: "linear",
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: "50%", top:"50%" }}
                                                whileInView={{ scale: 1, left: "16%", top:"84%" }}
                                            >
                                                <img src={IMAGES.LogoIcon7} alt="" />                                                
                                            </motion.li>
                                        </ul>
                                    </div>
                                    <div  className="center-media">
                                        <motion.img     
                                            transition={{
                                                ease: "linear",
                                                duration: 0.5,
                                                x: { duration: 1 }
                                            }}                                       
                                            initial={{ scale: 0,rotate:'0deg' }}
                                            whileInView={{ scale: 1,rotate:'360deg' }}
                                            src={IMAGES.LogoIcon} alt="" 
                                        />
                                    </div >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="coin-wave">
                        <motion.div className="flex-coin1"                                
                            transition={{
                                ease: "linear",
                                duration: 0.5,
                                x: { duration: 0.7 }
                            }}                         
                            initial={{ opacity: 0 ,y: '500px'}}
                            whileInView={{ opacity: 1, y: 0}}
                        >
                            <img src={IMAGES.LogoIcon2} alt="" />
                        </motion.div>
                        
                        <motion.div 
                            className="flex-coin2" 
                            transition={{
                                ease: "linear",
                                duration: 0.5,
                                x: { duration: 0.7 }
                            }}                         
                            initial={{ opacity: 0 ,y: '200px'}}
                            whileInView={{ opacity: 1, y: 0}}
                        >
                            <img src={IMAGES.LogoIcon4} alt="" />
                        </motion.div>
                        <div className="flex-coin3" 
                            data-400="transform:translateY(300px); opacity:0;" data-800="transform:translateY(0); opacity:1;"
                            
                        >
                            <img src={IMAGES.LogoIcon1} alt="" />
                        </div>
                        <motion.div className="flex-coin4" data-300="transform:translateY(350px); opacity:0;" data-700="transform:translateY(0); opacity:1;"
                            transition={{
                                ease: "linear",
                                duration: 0.5,
                                x: { duration: 0.7 }
                            }}                         
                            initial={{ opacity: 0 ,y: '350px'}}
                            whileInView={{ opacity: 1, y: 0}}
                        >
                            <img src={IMAGES.LogoIcon3} alt="" />
                        </motion.div>
                        <motion.div className="flex-coin5" data-400="transform:translateY(300px); opacity:0;" data-800="transform:translateY(0); opacity:1;"
                            transition={{
                                ease: "linear",
                                duration: 0.5,
                                x: { duration: 0.7 }
                            }}                         
                            initial={{ opacity: 0 ,y: '300px'}}
                            whileInView={{ opacity: 1, y: 0}}
                        >
                            <img src={IMAGES.LogoIcon5} alt="" />
                        </motion.div>
                    </div>
                </section>
                <section className="content-inner-2 content-bx style-1 ">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 m-b30 ">
                                <div className="dz-media">
                                    <img src={IMAGES.about1} alt="image" className="rounded" />
                                    <motion.div className="content-box wow bounceInLeft" data-wow-delay="0.6s"
                                        transition={{
                                            ease: "linear",
                                            duration: 0.5,
                                            x: { duration: 0.6 }
                                        }}                         
                                        initial={{ x: '-300px'}}
                                        whileInView={{ x: 0}}
                                    >
                                        <h6 className="m-a0">Access analytical <br/>market & price data</h6>
                                    </motion.div>
                                </div>
                            </div>
                            <div className="col-lg-6 m-b30">
                                <div className="inner-content">
                                    <div className="section-head">
                                        <h3 className="title wow fadeInUp" data-wow-delay="0.6s"                                            
                                        >The World’s 1st ICO <br/>Platform That Offers<br /> Rewards
                                        </h3>
                                        <motion.p className="font-text wow fadeInUp" data-wow-delay="0.8s"
                                            initial={{ opacity: 0,  y: '100px' }}
                                            whileInView={{ opacity: 1,  y: '0' }}
                                            transition={{ duration: 0.7 }}
                                            >Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
                                        </motion.p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-inner overflow-hidden">
                    <div className="container">
                        <div className="section-head text-center">
                            <h2 className="title wow fadeInUp" data-wow-delay="0.4s">Why <span className="text-primary">FutureFX?</span></h2>
                        </div>
                        <div className="row justify-content-center">
                            {iconWrapper.map((data, ind)=>(
                                <div className="col-lg-4 col-md-6 m-b30" key={ind}>
                                    <div className={`icon-bx-wraper style-1 box-hover text-center ${onHover === data.id ? 'active' : ''}`} data-wow-delay="0.6s" 
                                        onMouseOver={()=>setOnHover(data.id)}
                                    >
                                        <div className="icon-media"> 
                                            <img src={data.image} alt="" />
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="title">{data.title}</h4>
                                            <p>Lorem Ipsum has been the industry's standard dummy text ever.</p>
                                            <p>when an unknown printer took a galley of type and scrambledt.</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </section>    
            </div>
        </>
    )
}

export default connect() (Home);