import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const InputDropdown = ({name,placeholder,id, selected_value, errors, countryList, value, handleChange}) => {
    return (
        <div className="input-group wow fadeInUp" data-wow-delay="1.8s">
            <select defaultValue={value}
                    className="form-control"
                    style={{width:'100%'}}
                    name={name}
                    id={id}
                    onChange={handleChange}
                    value={value}
            >
                <option key="0" value="">{placeholder}</option>
                {
                    countryList && countryList.map(
                        row => <option value={row.name} key={row.id}>{row.name}</option>
                    )
                }
            </select>
            <div
                id={`${id}-error`}
                className="invalid-feedback animated fadeInUp p-0"
                style={{ display: "block" }}
            >
                {errors && errors}
            </div>
        </div>
    )
}


export default InputDropdown