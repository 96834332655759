import React ,{Fragment,useEffect,useState,Suspense,lazy} from 'react';
import {BrowserRouter, Routes, Route, Outlet,useNavigate} from 'react-router-dom';


//pages
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Registration from './pages/Registration';
import Login from './pages/Login';
import Profile from './pages/Profile';
import ContactUs from './pages/ContactUs';
// import Dashboard from './pages/Dashboard';
import EmailVerification from './pages/emailVerification';
import ResetPassword from './pages/reserPassword';
import ConfirmPassword from './pages/ConfirmPassword';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';


import './assets/css/style.css';
import 'react-modal-video/scss/modal-video.scss';
import Header from './components/Header';
import AnimatedCursor from './elements/AnimatedCursor';
import {connect} from "react-redux";
import {loginSync} from "./redux/action/login.action";
import Terms from "./pages/Terms";
const Dashboard = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./pages/Dashboard')), 400);
    });
});

let basename = "/";
const App = (props) => {

    let localData =  localStorage.getItem("isLogin");
    let first_name =  localStorage.getItem("first_name");
    let last_name =  localStorage.getItem("last_name");
    let user_id =  localStorage.getItem("user");
    let user_role =  localStorage.getItem("user_role");
    const [isLogin,setIsLogin] = useState(false);
    const {loginFunc, login} = props;

    useEffect(() => {
        // setTimeout(() => {
            if(user_id !== null){
                if((/true/).test(localData)){
                    let objToken = {
                        data :{
                            id: user_id,
                            first_name: first_name,
                            last_name: last_name,
                            user_role_id:user_role
                        },
                        isLogin:true
                    };

                    loginFunc(objToken,localStorage.getItem("token"))
                }
            }
        // }, );
    }, []);

    return (
      <>
        <Suspense>
          <Routes>
			<Route path="/dashboard" element = {<Dashboard login={login} />} />
			<Route path="/profile" element = {<Profile loginData={login} />} />
            <Route element= {<Layouts />} >
				<Route path="/" element = {<Home loginData={login} />} />
				<Route path="/about-us" element = {<AboutUs />} />
				<Route path="/contact-us" element = {<ContactUs />} />
				<Route path="/register" element = {<Registration />} />
				<Route path="/register/:code" element = {<Registration />} />
				<Route path="/email-verification/:user" element = {<EmailVerification />} />
				<Route path="/email-verification" element = {<EmailVerification />} />
				<Route path="/login" element = {<Login />} />
				<Route path="/reset-password" element = {<ResetPassword />} />
				<Route path="/confirm-password/:user" element = {<ConfirmPassword  />} />
				<Route path="/confirm-password" element = {<ConfirmPassword  />} />
				<Route path="/term-condition" element = {<Terms  />} />
			</Route>
          </Routes>
		  <AnimatedCursor />
		  <ScrollToTop />
        </Suspense>
      </>
    );
}

function Layouts(){
	return(
		<>
			<div className='page-wraper'>
				<Header style="header-transparent"/>
				<Outlet />
				<Footer />
			</div>			
		</>
	)
}


const MapDispatchToProps = dispatch => ({
    loginFunc: (data) => dispatch(loginSync(data)),
})

const initMapStateToProps = state => ({
    login: state.login
})


export default connect(initMapStateToProps,MapDispatchToProps)(App);
