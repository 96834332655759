
import Shape1  from '../assets/images/home-banner/shape1.svg';
import iconsvg3  from '../assets/images/icons/icon3.svg';
import iconsvg4  from '../assets/images/icons/icon4.svg';
import iconsvg5  from '../assets/images/icons/icon5.svg';
import iconsvg6  from '../assets/images/icons/icon6.svg';
import iconsvg7  from '../assets/images/icons/icon7.svg';
import iconsvg8  from '../assets/images/icons/icon8.svg';

export const IMAGES = {
    Digital : require('../assets/images/home-banner/shape/digitalwallet.png'),  
    Background2 : require('../assets/images/background/bg2.jpg'),  
	
    BlogSlider1 : require('../assets/images/blog/blog-slider/pic1.jpg'),  
    BlogSlider2 : require('../assets/images/blog/blog-slider/pic2.jpg'),  
    BlogSlider3: require('../assets/images/blog/blog-slider/pic3.jpg'),  
	
    Avatar1: require('../assets/images/avatar/avatar1.jpg'),  
    Avatar2: require('../assets/images/avatar/avatar2.jpg'),  
    Avatar3: require('../assets/images/avatar/avatar3.jpg'), 

    LogoIcon: require('../assets/images/logo-icon.png'), 
    LogoIcon1: require('../assets/images/coins/coin1.png'), 
    LogoIcon2: require('../assets/images/coins/coin2.png'), 
    LogoIcon3: require('../assets/images/coins/coin3.png'), 
    LogoIcon4: require('../assets/images/coins/coin4.png'), 
    LogoIcon5: require('../assets/images/coins/coin5.png'), 
    LogoIcon6: require('../assets/images/coins/coin6.png'), 
    LogoIcon7: require('../assets/images/coins/coin7.png'), 
    LogoIcon8: require('../assets/images/coins/coin8.png'), 

    ClientLogo1: require('../assets/images/clients-logo/logo1.png'),  
    ClientLogo2: require('../assets/images/clients-logo/logo2.png'),  
    ClientLogo3: require('../assets/images/clients-logo/logo3.png'),  
    ClientLogo4: require('../assets/images/clients-logo/logo4.png'),  
    ClientLogo5: require('../assets/images/clients-logo/logo5.png'),  

    BannerShape1 : Shape1,  
    Doublehand : iconsvg3,  
    World : iconsvg4,  
    ArrowCoin : iconsvg5,  
    CardSvg6 : iconsvg6,  
    CardSvg7 : iconsvg7,  
    CardSvg8 : iconsvg8,  
	
    coin3 : require('../assets/images/coins/coin3.png'),  
    coin7 : require('../assets/images/coins/coin7.png'),  
    coin8 : require('../assets/images/coins/coin8.png'),  
    about1 : require('../assets/images/about/pic1.jpg'),  
    about2 : require('../assets/images/about/pic2.jpg'), 
    store : require('../assets/images/app-store.png'), 
    play : require('../assets/images/play-store.png'), 

    BlogPic1 : require('../assets/images/blog/pic1.jpg'), 
    BlogPic3 : require('../assets/images/blog/pic3.jpg'), 
    BlogPic4 : require('../assets/images/blog/pic4.jpg'), 
    BlogPic5 : require('../assets/images/blog/pic5.jpg'), 
    BlogPic6 : require('../assets/images/blog/pic6.jpg'), 
    BlogPic : require('../assets/images/blog/blog.jpg'), 
    
    BlogSmall1 : require('../assets/images/blog/small/pic1.jpg'), 
    BlogSmall2 : require('../assets/images/blog/small/pic2.jpg'), 
    BlogSmall3 : require('../assets/images/blog/small/pic3.jpg'), 
    
    
}

export const SVGICON = {
    
    Arrow : 
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.1665 10H15.8332" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 4.16666L15.8333 9.99999L10 15.8333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,

    FooterArrow : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
        <g clipPath="url(#l)"><path d="M.246 17.179c-.148.68.374.967.858.75l16.365-8.391h.002c.195-.11.308-.308.308-.537s-.113-.428-.308-.538h-.002L1.104.071C.62-.146.098.142.246.822L1.75 7.521 10.293 9 1.75 10.479.246 17.179z" fill="#fff"/></g><defs><clipPath id="l"><path fill="#fff" d="M0 0h18v18H0z"/></clipPath></defs>
    </svg>,
}

const appTheme = {
    IMAGES, 
    SVGICON
}

export default appTheme;