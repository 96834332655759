import React,{useState} from 'react';
import {IMAGES} from '../constants/theme';
import PageTitle from '../elements/PageTitle';

const Terms = () => {
    return(
        <>
        <div className="page-content">
            <PageTitle activeTitle="Term & Conditions"  parentTitle ="Home"	/>

            <section className=" content-bx style-2 mt-5 mb-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 ">
                            <div className="inner-content ">
                                <div className="section-head">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </>
    )
}

export default Terms;