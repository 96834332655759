import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import ModalVideo from 'react-modal-video'
import {IMAGES} from '../constants/theme';
import PageTitle from '../elements/PageTitle';
import LatestNewsSlider from '../components/LatestNewsSlider';
import ClientSlider from '../components/ClientSlider';
import NeedHelp from '../components/NeedHelp';

const mediaBlog = [
	{title: 'Instant Trading', image: IMAGES.CardSvg6, id:'1'},
	{title: 'Recurring Buying', image: IMAGES.CardSvg7, id:'2'},
	{title: 'Safe And Secure', image: IMAGES.CardSvg8, id:'3'},
];

const counterBlog = [
	{title: "Support Countries", count: "12" },
	{title: "BitCoin ATMs", count: "259" },
	{title: "Producers", count: "108" },
	{title: "Operators", count: "19" },
];

const AboutUs = () => {
	const [hoverEffect, sethHoverEffect] = useState('1');
	return(
		<>
			<div className="page-content">
				<PageTitle activeTitle="WE ARE FUTUREFX"  parentTitle ="Home"	/>

				<section className=" content-bx style-2 mt-5 mb-5">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 ">
								<div className="inner-content ">
									<div className="section-head">
										<p className="font-text  text-dark font-w500 wow fadeInUp" data-wow-delay="0.4s">The concept of “Passive Income” is rapidly spreading across the globe. </p>

										<p className="wow fadeInUp" style={{fontSize:'16px'}}  data-wow-delay="0.6s">

											Currently, millions of traders around the world are reaping enormous financial rewards. FUTUREFX mission is to help individuals with hectic schedules generate a passive income (Passive Income) in a low-risk high-reward Investment Environment through the smart money concept. These investments are risk-free and should provide you with satisfactory returns. The investor controls all deposits, withdrawals, and resulting profits. The FUTUREFX team is available whenever you need them to provide you with the assistance and direction you require. We are based in the Australia.

										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="dz-media inner-content wow bounceInRight rounded" data-wow-delay="1.0s">
									<img src={IMAGES.about2} alt="sec" />
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}

export default AboutUs;