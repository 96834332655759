import React,{useState,useEffect} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import Header from '../components/Header';
import logo from "../assets/images/logo-dark.png";
import Input from '../components/Input'
import * as Yup from "yup";
import {Formik} from 'formik';
import {connect} from 'react-redux';
import {tokenGenerateAsync} from "../redux/action/passwordReset.action";
import AlertBox from "../components/Alert";

const resetSchema = Yup.object().shape({
    email: Yup.string()
        .required("Please enter a email")
        .email('Please provide valid email')
});

const ResetPassword = (props) => {

    const { tokenGenarateFunc, settings, login} = props;
    const navigate = useNavigate();
    const [fired, setFired] = useState(false);
    const [icon, setIcon] = useState("");
    const [slog, setSlog] = useState("");

    useEffect(() => {
        setTimeout(() => {
            if(login.isLogin)
                navigate("/");
        },100)
    }, []);

    useEffect(() => {
        if(login.isLogin)
            navigate("/");
    }, [login]);

    const handleFormSubmit = (data) => {
        setFired(false)
        tokenGenarateFunc(data)
            .then(() => {
                setFired(true)
            })
    }

    useEffect(() => {
        if(settings.status){
            setIcon("success")
            setSlog("Success")
            setTimeout( () =>{
                navigate("/confirm-password/");
            },7000)
        }else{
            setIcon("danger")
            setSlog("Fail")
        }
    }, [settings]);

    return(
        <>
        {/*<Header style="box-shadow-none"/>*/}
        <div className="page-content">
            <section className="style-1 map-wrapper1 overflow-hidden dashboard">
                <div className="row">
                    <div className="col-xl-3 "></div>
                    <div className="col-xl-6  col-sm-12">
                        <div className="form-wrapper style-1 pt-2 mt-5">
                            <div className="register-logo">
                                <Link to={"/"}><img wi src={logo} alt="" /></Link>
                            </div>
                            <h4 className="title m-a0 wow fadeInUp text-center mb-3" style={{color:'#FFF'}} data-wow-delay="1.6s">
                                Reset Password
                            </h4>

                            {
                                fired ?  <AlertBox message={settings.message} icon={icon} slog={slog}/> : ""
                            }

                            <div className="contact-area">
                                <Formik
                                    initialValues={{
                                        email: "",
                                    }}
                                    validationSchema={resetSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            handleFormSubmit(values)
                                            setSubmitting(false);
                                        }, 400);
                                    }}

                                >
                                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                                            <div className="dzFormMsg"></div>
                                            <div className="row ">
                                                <div className="col-md-12">
                                                    <Input
                                                        name="email"
                                                        id="email"
                                                        placeholder="Email"
                                                        errors={errors.email}
                                                        value={values.email}
                                                        handleChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-12 p-t20">
                                                    <button name="submit" type="submit" value="Login" className="btn btn-primary wow fadeInUp" data-wow-delay="2.3s">Reset Password</button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 "></div>
                </div>
            </section>
        </div>
        {/*<Footer />*/}
        </>
    )

}

const initMapDispatchToProps = dispatch => ({
    tokenGenarateFunc: (data) => dispatch(tokenGenerateAsync(data))
})

const initMapStateToProps = state => ({
    settings: state.settings,
    login: state.login
})

export default connect(initMapStateToProps,initMapDispatchToProps)(ResetPassword);