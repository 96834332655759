import React,{useRef} from 'react';
import {Link} from 'react-router-dom';
import emailjs from '@emailjs/browser';
import swal from "sweetalert";
import { IMAGES, SVGICON } from '../constants/theme';

import twit from '../assets/images/social/twitter.png';
import facebook from '../assets/images/social/facebook.png';
import linkd from '../assets/images/social/linkedin.png';
import insta from '../assets/images/social/instagram.png';

const Footer = () => {
    return (
        <>
            <footer className="site-footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="widget widget_about wow fadeInUp" data-wow-delay="0.4s">
                                    <h6 className="footer-title mb-3 ">Coin Rates</h6>
                                    <ul>
                                        <li>Bitcoin BTC $34,678.50</li>
                                        <li>Ethereum ETH $1,845.32 </li>
                                        <li>Tether USDT $1.000393</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-6">
                                <div className="widget widget_links wow fadeInUp" data-wow-delay="0.6s">
                                    <h6 className="footer-title">Our links</h6>
                                    <ul>
                                        <li><Link to={"/about-us"}>About Us </Link></li>
                                        <li><Link to={"/contact-us"}>Contact Us </Link></li>
                                    </ul>
                                </div>
                            </div>
                            {/*<div className="col-lg-2 col-md-3 col-6">*/}
                                {/*<div className="widget widget_links wow fadeInUp">*/}
                                    {/*<h6 className="footer-title">&nbsp;</h6>*/}
                                    {/*<ul>*/}
                                        {/*<li><Link to={"/term-condition"}>Privacy Policy</Link></li>*/}
                                    {/*</ul>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="col-lg-2 col-md-3 col-6">
                                <div className="widget widget_links wow fadeInUp" data-wow-delay="1.2s">
                                    <h6 className="footer-title">Support</h6>
                                    <ul>
                                        <li><Link to={"/contact-us"}>Contact us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-6">
                                <div className="widget widget-social wow fadeInUp" data-wow-delay="1.4s">
                                    <h6 className="footer-title">Email</h6>
                                    <ul>
                                        <li>
                                            <a href = "mailto: officialfuturefx@gmail.com">
                                            officialfuturefx@gmail.com
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>  
        </>
    );
};

export default Footer;