import React, { useRef, useState } from "react";
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper";
import { IMAGES } from "../constants/theme";

const sliderDate = [
 {image:IMAGES.ClientLogo1},
 {image:IMAGES.ClientLogo2},
 {image:IMAGES.ClientLogo3},
 {image:IMAGES.ClientLogo2},
 {image:IMAGES.ClientLogo1},
 {image:IMAGES.ClientLogo3},
];

export default function LatestNewsSlider() {
  return (
    <>
      <Swiper 
        //navigation={true} 
        modules={[Navigation]} 
        speed={1500}
        spaceBetween={30}
        slidesPerView={5}
        loop={true}
        breakpoints= {{
          1191: {
            slidesPerView: 5,
          },
          992: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          575: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 2,
          },
        }}
        className="swiper clients-swiper"        
    >
        {sliderDate.map((item, ind)=>(
            <SwiperSlide key={ind}>
                <div className="clients-logo">
                    <Link to={"#"}><img className="logo-main " src={item.image} alt="" /></Link>
                </div>
            </SwiperSlide>        
        ))}
      </Swiper>
    </>
  );
}