const init = {
    status:false,
    message:''
}

export const contactReducer = (state = init, action) => {
    switch (action.type){
        case 'CONTACT_START':
            return state;
        case 'CONTACT_FAIL':
            return {...state, status:false, message:action.payload};
        case 'CONTACT_SUCCESS':
            return {...state, status:true, message:action.payload };
        default:
            return state;
    }
};