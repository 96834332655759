import {createStore,applyMiddleware,combineReducers} from 'redux'
import {loginReducer} from "./reducer/login.reducer";
import {fetchCountryReducer} from "./reducer/fetchCountries.reducer";
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import {registerReducer} from "./reducer/register.reducer";
import {emailVerificationReducer} from "./reducer/emailVerification.reducer";
import {passwordResetReducer} from "./reducer/passwordReset.reducer";
import {fetchRecentActivityReducer} from "./reducer/fetchRecentActivity.reducer";
import {fetchTransactionReducer} from "./reducer/transaction.reducer";
import {fetchSummeryReducer} from "./reducer/fetchSummery.reducer";
import {withdrawReducer} from "./reducer/withdraw.reducer";
import {getNotificationReducer} from "./reducer/getNotification.reducer";
import {depositReducer} from "./reducer/deposit.reducer";
import {contactReducer} from "./reducer/contact.reducer";
const rootReducer = combineReducers({
    login:loginReducer,
    countries:fetchCountryReducer,
    register:registerReducer,
    emailVerification:emailVerificationReducer,
    settings:passwordResetReducer,
    activity:fetchRecentActivityReducer,
    transaction:fetchTransactionReducer,
    summery:fetchSummeryReducer,
    withdraw:withdrawReducer,
    deposit:depositReducer,
    activeNotifications:getNotificationReducer,
    contact:contactReducer,
})

const middleware = [thunk]
export const store = createStore(rootReducer,applyMiddleware(...middleware))