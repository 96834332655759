const init = {
    data: {},
    message: '',
    status: false
}
export const fetchSummeryReducer = (state=init, action) => {
    switch(action.type){
        case 'FETCH_SUMMERY_START':
            return state;
        case 'FETCH_SUMMERY_SUCCESS':
            return {...state, data:action.payload, status: true};
        case 'FETCH_SUMMERY_ERROR':
            return {...state, message: action.payload};
        default:
            return state;
    }
};