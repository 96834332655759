import React,{useState,useEffect} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import Header from '../components/Header';
import logo from "../assets/images/logo-dark.png";
import Input from '../components/Input'
import Password from '../components/Password'
import * as Yup from "yup";
import {Formik} from 'formik';
import {connect} from 'react-redux';
import {resetPasswordAsync} from "../redux/action/passwordReset.action";
import AlertBox from "../components/Alert";

const resetSchema = Yup.object().shape({
    token: Yup.string()
        .required("Please provide a token"),
    user_password: Yup.string()
        .required("Please provide a password"),
    confirm_password: Yup.string()
        .required("Please provide a confirm password")
        .oneOf([Yup.ref('user_password'), null], 'Confirm Passwords must match with Password'),
});

const ConfirmPassword = (props) => {

    let url = window.location.pathname
    let aCode = url.split("/");
    const { resetPassFuncFunc, settings,login} = props;
    let code = typeof aCode[2] !== 'undefined' ? aCode[2] : settings.data > 0 ? settings.data : "";
    const [param, setParam] = useState(code);
    const [fired, setFired] = useState(false);
    const [icon, setIcon] = useState("");
    const [slog, setSlog] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            if(code.length === 0 )
                navigate("/");
            else
                setParam(code)
        }, 100);
    }, []);

    useEffect(() => {
        if(login.isLogin)
            navigate("/");
    }, [login]);

    const handleFormSubmit = (data) => {
        setFired(false)
        let objToken = {
            user: param
        };
        let postData = Object.assign({}, data, objToken);
        resetPassFuncFunc(postData).then(() => {
            setFired(true)
        })
    }

    useEffect(() => {
        if(settings.status){
            setIcon("success")
            setSlog("Success")
            setTimeout( () =>{
                navigate("/login");
            },7000)
        }else{
            setIcon("danger")
            setSlog("Fail")
        }
    }, [settings]);

    return(
        <>
        {/*<Header style="box-shadow-none"/>*/}
        <div className="page-content">
            <section className="style-1 map-wrapper1 overflow-hidden dashboard">
                <div className="row">
                    <div className="col-xl-3 "></div>
                    <div className="col-xl-6  col-sm-12">
                        <div className="form-wrapper style-1 pt-2 mt-5">
                            <div className="register-logo">
                                <Link to={"/"}><img src={logo} alt="" /></Link>
                            </div>
                            <h4 className="title m-a0 wow fadeInUp text-center mb-3" style={{color:'#FFF'}} data-wow-delay="1.6s">
                                Reset Password
                            </h4>

                            {
                                fired ?  <AlertBox message={settings.message} icon={icon} slog={slog}/> : ""
                            }

                            <div className="contact-area">
                                <Formik
                                    initialValues={{
                                        token: "",
                                        user_password: "",
                                        confirm_password: "",
                                    }}
                                    validationSchema={resetSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            handleFormSubmit(values)
                                            setSubmitting(false);
                                        }, 400);
                                    }}

                                >
                                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                                            <div className="dzFormMsg"></div>
                                            <div className="row ">
                                                <div className="col-md-12">
                                                    <Input
                                                        name="token"
                                                        id="token"
                                                        placeholder="Token Number"
                                                        errors={errors.token}
                                                        value={values.token}
                                                        handleChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <Password
                                                        name="user_password"
                                                        id="user_password"
                                                        placeholder="Password"
                                                        errors={errors.user_password}
                                                        value={values.user_password}
                                                        handleChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <Password
                                                        name="confirm_password"
                                                        id="confirm_password"
                                                        placeholder="Confirm Password"
                                                        errors={errors.confirm_password}
                                                        value={values.confirm_password}
                                                        handleChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-12 p-t20">
                                                    <button name="submit" type="submit" value="Login" className="btn btn-primary wow fadeInUp" data-wow-delay="2.3s">Reset Password</button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 "></div>
                </div>
            </section>
        </div>
        {/*<Footer />*/}
        </>
    )

}

const initMapDispatchToProps = dispatch => ({
    resetPassFuncFunc: (data) => dispatch(resetPasswordAsync(data))
})

const initMapStateToProps = state => ({
    settings: state.settings,
    login: state.login
})

export default connect(initMapStateToProps,initMapDispatchToProps)(ConfirmPassword);