const init = {
    data: {},
    message: '',
    status: false
}
export const fetchTransactionReducer = (state=init, action) => {
    switch(action.type){
        case 'FETCH_TRANSACTION_START':
            return {...state, data:{}, message: '', status: false};
        case 'FETCH_TRANSACTION_SUCCESS':
            return {...state, data:action.payload, message: '', status: true};
        case 'FETCH_TRANSACTION_ERROR':
            return {...state, data:{}, message: action.payload, status: false};
        default:
            return state;
    }
};