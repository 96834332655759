const init = {
    status:false,
    message:'',
    data:0
}

export const withdrawReducer = (state = init, action) => {
    switch (action.type){
        case 'WITHDRAW_START':
            return state;
        case 'WITHDRAW_FAIL':
            return {...state, message:action.payload};
        case 'WITHDRAW_SUCCESS':
            return {...state, status:true, message:action.payload };
        default:
            return state;
    }
};