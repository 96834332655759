let init = {
    message:'',
    status:false,
    data:0
}
export const passwordResetReducer = (state = init, action) => {
    switch (action.type){
        case 'RESET_PASSWORD_START':
            return state;
        case 'RESET_PASSWORD_ERROR':
            return {...state, message:action.payload };
        case 'RESET_PASSWORD_SUCCESS':
            return {...state, message:action.payload.message, data:action.payload.data, status:true };
        default:
            return state;
    }
}