import API from '../../Api';

const emailVerificationStart = () => ({
    type:'VERIFY_EMAIL_START'
})

const emailVerificationSuccess = payload => ({
    type: 'VERIFY_EMAIL_SUCCESS',
    payload
})

const emailVerificationFailure = error => ({
    type:'VERIFY_EMAIL_ERROR',
    payload:error
})

export const  verifyEmailAsync = (data) => async dispatch => {
    dispatch(emailVerificationStart())
    try {

        const resp = await API.post('verify-email',data)
        const aData = resp.data
        if(aData.status)
            dispatch(emailVerificationSuccess(aData.message))
        else
            dispatch(emailVerificationFailure(aData.message))

    }catch (e){
        dispatch(emailVerificationFailure(e.message))
    }
};
export const  verifyEmailResendAsync = (data) => async dispatch => {
    dispatch(emailVerificationStart())
    try {

        const resp = await API.post('verified',data);
        const aData = resp.data
        if(aData.status)
            dispatch(emailVerificationSuccess(aData.message))
        else
            dispatch(emailVerificationFailure(aData.message))

    }catch (e){
        dispatch(emailVerificationFailure(e.message))
    }
};