import API from '../../Api';

export const contactStart = () => ({
    type:"CONTACT_START"
})

export const contactSuccess = payload => ({
    type:"CONTACT_SUCCESS",
    payload
})

export const contactFail = payload => ({
    type:"CONTACT_FAIL",
    payload
})

export const  emailSendAsync = (data) => async dispatch => {
    dispatch(contactStart())
    try {
        const resp = await API.post('send-message',data);
        const aData = resp.data
        if(aData.status){
            dispatch(contactSuccess(aData.message))
        }
        else
            dispatch(contactFail(aData.message))

    }catch (e){
        dispatch(contactFail(e.message))
    }
};