import React,{useEffect,useState} from 'react';
import {Link,NavLink,useNavigate} from 'react-router-dom';
import logo from "../assets/images/logo-dark.png";
import Input from '../components/Input'
import Password from '../components/Password'
import * as Yup from "yup";
import {Formik} from 'formik';
import {connect} from 'react-redux';
import {loginAsync} from "../redux/action/login.action";
import AlertBox from "../components/Alert";

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .required("Please enter a username")
        .email('Please provide valid email'),
    user_password: Yup.string()
        .required("Please provide a password"),
});

const Login = (props) => {

    const { loginFunc, login } = props;
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [fired, setFired] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if(login.isLogin)
                navigate("/");
        }, 100);
    }, []);

    useEffect(() => {
        if(login.isLogin)
            navigate("/");
        else
            setMessage(login.message)
    }, [login]);

    const handleFormSubmit = (data) => {
        setFired(false)
        loginFunc(data)
            .then(()=>{
                setFired(true)
            })
    };

        return(
            <>
            {/*<Header style="box-shadow-none"/>*/}
            <div className="page-content">
                <section className="style-1 map-wrapper1 overflow-hidden dashboard">
                    <div className="row">
                        <div className="col-xl-3 "></div>
                        <div className="col-xl-6  col-sm-12">
                            <div className="form-wrapper style-1 pt-2 mt-5">
                                <div className="register-logo">
                                    <Link to={"/"}><img src={logo} alt="" /></Link>
                                </div>
                                {
                                    !login.isLogin && fired ?  <AlertBox message={message} icon="danger" slog="Fail"/> : ""
                                }

                                <h4 className="title m-a0 wow fadeInUp text-center mb-3" style={{color:'#FFF'}} data-wow-delay="1.6s">
                                    Login
                                </h4>

                                <div className="contact-area">
                                    <Formik
                                        initialValues={{
                                            email: "",
                                            user_password: ""
                                        }}
                                        validationSchema={loginSchema}
                                        onSubmit={(values, { setSubmitting }) => {
                                            setTimeout(() => {
                                                handleFormSubmit(values)
                                                setSubmitting(false);
                                            }, 400);
                                        }}

                                    >
                                        {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                            <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                                                <div className="dzFormMsg"></div>
                                                <div className="row ">
                                                    <div className="col-md-12">
                                                        <Input
                                                            name="email"
                                                            id="email"
                                                            placeholder="Email"
                                                            errors={errors.email}
                                                            value={values.email}
                                                            handleChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Password
                                                            name="user_password"
                                                            id="user_password"
                                                            placeholder="Password"
                                                            errors={errors.user_password}
                                                            value={values.user_password}
                                                            handleChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 p-t20">
                                                        <button name="submit" type="submit" value="Login" className="btn btn-primary wow fadeInUp" data-wow-delay="2.3s">Login</button>
                                                        <NavLink to={"/reset-password"} className="m-l20">Forgot Password</NavLink>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 "></div>
                    </div>
                </section>
            </div>
            {/*<Footer />*/}
            </>
        )

}

const initMapDispatchToProps = dispatch => ({
    loginFunc: (data) => dispatch(loginAsync(data))
})

const initMapStateToProps = state => ({
    login: state.login
})

export default connect(initMapStateToProps,initMapDispatchToProps)(Login);