const init = {
    status:false,
    message:'',
    data:0
}
export const registerReducer = (state = init, action) => {
    switch (action.type){
        case 'START_REGISTER':
            return state;
        case 'REGISTER_ERROR':
            return {...state, message:action.payload, status:false, data:0};
        case 'REGISTER_SUCCESS':
            return {...state, data:action.payload, status:true, message:''};
        default:
            return state;
    }
}