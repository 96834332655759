const init = {
    status:false,
    message:'',
    data:0
}

export const depositReducer = (state = init, action) => {
    switch (action.type){
        case 'DEPOSIT_START':
            return state;
        case 'DEPOSIT_FAIL':
            return {...state, message:action.payload};
        case 'DEPOSIT_SUCCESS':
            return {...state, status:true, message:action.payload.message, data:action.payload.data };
        default:
            return state;
    }
};