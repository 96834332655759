import React,{useState,useEffect} from 'react';
import DashboardHeader from '../components/DashboardHeader';
import Input from '../components/Input'
import * as Yup from "yup";
import * as formik from 'formik';
import {useNavigate} from 'react-router-dom';
import Footer from "../components/Footer";
import {connect} from "react-redux";
import {p2pAddressAsync} from "../redux/action/register.action";
import AlertBox from "../components/Alert";


const Profile = (props) => {
    const { Formik } = formik;
    const { login,ptpFunc,regData} = props;
    const [fired, setFired] = useState(false);
    const [icon, setIcon] = useState("");
    const [slog, setSlog] = useState("");
    const navigate = useNavigate();
    const walletSchema = Yup.object().shape({
        wallet_id: Yup.string()
            .required("Please provide wallet id")
    });

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        if(regData.status){
            setIcon("success")
            setSlog("Success")
            setTimeout(() => {
                window.location.replace("/dashboard");
            },2000)
        }else{
            setIcon("danger")
            setSlog("Fail")
        }

    }, [regData]);

    const handleFormSubmit = (values) => {
        let objToken = {
            user: login.data.id
        };
        let postData = Object.assign({}, values, objToken);
        setFired(false);
        ptpFunc(postData)
            .then(() => {
                setFired(true)
            })
    }

    return(
        <>
        <DashboardHeader style="box-shadow-none"/>
        <div className="page-content dashboard">
        <div className="row">
            <div className="col-xl-12 col-lg-12 p-12">
                <div className="clearfix">
                    <div className="card card-bx profile-card author-profile m-b30">
                        <div className="card-body">
                            <div className="p-5">
                                <div className="author-profile">
                                    <div className="author-info">
                                        <h4 className="text-center" style={{color:'var(--primary)'}}>
                                            {login.isLogin ? login.data.first_name+" "+login.data.last_name : "" }
                                            {/*&nbsp;&nbsp;*/}
                                            {/*<svg style={{cursor:'pointer'}} onClick={()=>{navigator.clipboard.writeText("TSSJmZKThL523FgFGMuZnSRGKHq1YwNBif")}}  xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3" id="IconChangeColor">*/}
                                                {/*<path fill="var(--primary)" stroke="var(--primary)" d="M12 20h9" id="mainIconPathAttribute"></path>*/}
                                                {/*<path fill="var(--primary)" stroke="var(--primary)" d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" id="mainIconPathAttribute"></path>*/}
                                            {/*</svg>*/}

                                        </h4>
                                        <div className="text-center" style={{color:'#fff', fontSize:'11px'}} >Code</div>
                                        <h6 className="title text-center" style={{color:'var(--primary)'}}>
                                            {login.isLogin ? login.data.invite_code : "-" }
                                        </h6>
                                        <div className="text-center" style={{color:'#fff', fontSize:'11px'}} >Email</div>
                                        <h6 className="title text-center" style={{color:'var(--primary)'}}>
                                            {login.isLogin ? login.data.email : "-" }
                                        </h6>
                                        <div className="text-center" style={{color:'#fff', fontSize:'11px'}} >Last Login Date</div>
                                        <h6 className="title text-center" style={{color:'var(--primary)'}}>
                                            {login.isLogin ? formatDate(new Date(login.data.last_login_date)) : "-" }
                                        </h6>
                                        <div className="text-center" style={{color:'#fff', fontSize:'11px'}} >Country</div>
                                        <h6 className="title text-center" style={{color:'var(--primary)'}}>
                                            {login.isLogin ? login.data.country_name : "-" }
                                        </h6>
                                        <div className="text-center" style={{color:'#fff', fontSize:'11px'}} >P2P Address</div>
                                        <h6 className="title text-center" style={{color:'var(--primary)'}}>
                                            {login.isLogin ? login.data.p2p_address : "-" }
                                        </h6>
                                        <div className="text-center" style={{color:'#fff', fontSize:'11px'}} >Referral Link</div>
                                        <h6 className="title text-center" style={{color:'var(--primary)'}}>
                                            {login.isLogin ? "https://futurefx.com.au/register/"+login.data.invite_code : "-" }
                                            &nbsp;
                                            {
                                                login.isLogin ? <svg style={{cursor:'pointer'}} onClick={()=>{navigator.clipboard.writeText("https://futurefx.com.au/register/"+login.data.invite_code)}} x="0px" y="0px" viewBox="0 0 115.77 122.88" height="12" width="12" xmlns="http://www.w3.org/2000/svg">
                                                    <path className="st0" d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z" fill="var(--primary)" stroke="var(--primary)" />
                                                </svg> : ""
                                            }

                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12 col-lg-12 p-12">
                <div className="clearfix">
                    <div className="card card-bx profile-card author-profile m-b30">
                        <div className="card-body">
                            <div className="p-5">
                                <div className="author-profile">
                                    <div className="author-info">
                                        <h4 style={{color:'#FFF'}}>Please Enter TRC20 Wallet Address</h4>
                                        {
                                            fired ?  <AlertBox message={regData.message} icon={icon} slog={slog}/> : ""
                                        }
                                        <Formik
                                            onSubmit={(values, { setSubmitting }) => {
                                                setTimeout(() => {
                                                    handleFormSubmit(values);
                                                    setSubmitting(false);
                                                }, 400);
                                            }}
                                            initialValues={{
                                                wallet_id: ''
                                            }}
                                            validationSchema={walletSchema}
                                        >
                                            {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                                <form className="dz-form dzForm style-1 contact-bx"  noValidate onSubmit={handleSubmit} >
                                                    <div className="row ">
                                                        <div className="col-md-12">
                                                            <Input name="wallet_id"
                                                                   id="wallet_id"
                                                                   placeholder="Wallet ID"
                                                                   errors={errors.wallet_id}
                                                                   value={values.wallet_id}
                                                                   handleChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className="col-md-12 p-t20">
                                                            <button name="submit" type="submit" value="Submit" className="btn btn-primary wow fadeInUp" data-wow-delay="2.3s">Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Footer />
        </>
    )
}

const mapStateToProps = state => ({
     login: state.login,
     regData: state.register,
})

const MapDispatchToProps = dispatch => ({
    ptpFunc: (data) => dispatch(p2pAddressAsync(data)),
})
export default connect(mapStateToProps,MapDispatchToProps) (Profile);