import API from '../../Api';

const fetchCountriesStart = () => ({
    type:'FETCH_COUNTRIES_START'
})

const fetchCountriesSuccess = payload => {
    return {
        type: 'FETCH_COUNTRIES_SUCCESS',
        payload
    }
}

const fetchCountriesFailure = error => ({
    type:'FETCH_COUNTRIES_ERROR',
    payload:error
})

export const  fetchCountriesAsync = () => async dispatch => {
    dispatch(fetchCountriesStart())
    try {

        const resp = await API.get('countries')
        const aData = resp.data
        if(aData.status)
            dispatch(fetchCountriesSuccess(aData.data))
        else
            dispatch(fetchCountriesFailure(aData.message))

    }catch (e){
        dispatch(fetchCountriesFailure(e.message))
    }
}