import API from '../../Api';

export const registerStart = payload => ({
    type:"START_REGISTER",
    payload
})

export const registerSuccess = payload => ({
    type: 'REGISTER_SUCCESS',
    payload
})

export const registerFailure = error => ({
    type:'REGISTER_ERROR',
    payload:error
})

export const  registerAsync = data => async dispatch => {
    dispatch(registerStart(data))
    try {

        const resp = await API.post('registration',data)
        const aData = resp.data
        if(aData.status){
            let id = aData.data;
            await API.post('verified',{user_id: id});
            dispatch(registerSuccess(aData.data))
        }
        else
            dispatch(registerFailure(aData.message))

    }catch (e){
        dispatch(registerFailure(e.message))
    }
}

export const  p2pAddressAsync = data => async dispatch => {
    dispatch(registerStart(data))
    try {
        const resp = await API.post('setP2p',data)
        const aData = resp.data
        if(aData.status){
            dispatch(registerSuccess(aData.message))
        }
        else
            dispatch(registerFailure(aData.message))

    }catch (e){
        dispatch(registerFailure(e.message))
    }
}
