import API from '../../Api';

const resetPasswordStart = () => ({
    type:'RESET_PASSWORD_START'
})

const resetPasswordSuccess = payload => ({
    type: 'RESET_PASSWORD_SUCCESS',
    payload
})

const resetPasswordFailure = error => ({
    type:'RESET_PASSWORD_ERROR',
    payload:error
})

export const tokenGenerateAsync = (data) => async dispatch => {
    dispatch(resetPasswordStart())
    try {

        const resp = await API.post('reset-password',data)
        const aData = resp.data
        if(aData.status){
            let objToken = {
                message: aData.message,
                data:aData.data
            };
            dispatch(resetPasswordSuccess(objToken))
        }
        else
            dispatch(resetPasswordFailure(aData.message))

    }catch (e){
        dispatch(resetPasswordFailure(e.message))
    }
};
export const  resetPasswordAsync = (data) => async dispatch => {
    dispatch(resetPasswordStart())
    try {

        const resp = await API.post('confirm-password',data);
        const aData = resp.data
        if(aData.status)
            dispatch(resetPasswordSuccess(aData.message))
        else
            dispatch(resetPasswordFailure(aData.message))

    }catch (e){
        dispatch(resetPasswordFailure(e.message))
    }
};