import React, { useEffect, useState } from 'react';
import {Link, NavLink} from 'react-router-dom';
import logo from '../assets/images/logo-dark.png';
import logoWhite from '../assets/images/logo.jpg';
import Registration from '../pages/Registration'
import {  Route, Routes, useLocation , useNavigate , useParams } from 'react-router-dom';
import {connect} from "react-redux";


function NavMenuToggle(){
    setTimeout(()=>{
        let toggleBtn = document.getElementById('navbutton');
        let sidebarOpen = document.getElementById('navbarNavDropdown');
        toggleBtn.classList.toggle("open");
        sidebarOpen.classList.toggle("show")
    },200)
}

const Header = (props) => {
    const [headerFix, setheaderFix] = useState(false);
    const { login} = props;
    const [isLogin,setIsLogin] = useState(login.isLogin);

    useEffect(() => {
        setTimeout(() => {
            setIsLogin(login.isLogin)
        },10);
    }, [login]);


    useEffect(() => {
        window.addEventListener("scroll", () => {
            setheaderFix(window.scrollY > 50);
        });
    }, []);

    return (
        <header className={`site-header mo-left header ${props.style}`}>
            <div className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""}`}>
                <div className="main-bar clearfix ">
                    <div className="container clearfix">
                        <div className="logo-header">
                            <Link to={"/"} className="logo-light"><img src={logoWhite} alt=""/></Link>
                            <Link to={"/"} className="logo-dark"><img src={logo} alt=""/></Link>
                        </div>
                        <button className="navbar-toggler collapsed navicon justify-content-end" type="button" id="navbutton"
                                onClick={()=>NavMenuToggle()}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        {
                            !isLogin ?
                                <div className="extra-nav p-l0" style={{paddingLeft:0}}>
                                    <div className="extra-cell">
                                        <Link to={"/login"} className="btn btn-outline-primary text-primary">Login</Link>
                                        <Link to={"/register"} className="btn btn-primary  btn-shadow">Register</Link>
                                    </div>
                                </div>
                                :
                                ""
                        }


                        <div className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                            <div className="logo-header logo-dark">
                                <Link to={"#"}><img src={logo} alt="" /></Link>
                            </div>
                            <ul className="nav navbar-nav navbar navbar-left">
                                <li><NavLink to={"/"}>Home</NavLink></li>
                                <li><NavLink to={"/about-us"}>About Us</NavLink></li>
                                <li><NavLink to={"/contact-us"}>Contact Us</NavLink></li>
                                {
                                    isLogin && isLogin ? <li><NavLink to={"/dashboard"}>Investing</NavLink></li> : ""
                                }

                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

const initMapStateToProps = state => ({
    login: state.login
})

export default connect(initMapStateToProps) (Header);
