import React,{useState,useEffect} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import Header from '../components/Header';
import logo from "../assets/images/logo-dark.png";
import Input from '../components/Input'
import * as Yup from "yup";
import {Formik} from 'formik';
import {connect} from 'react-redux';
import {verifyEmailAsync, verifyEmailResendAsync} from "../redux/action/emailVerification.action";
import AlertBox from "../components/Alert";

const validateSchema = Yup.object().shape({
    verification_code: Yup.string()
        .required("Please provide a verification code")
        .min(5,"Please provide valid verification code")
});

const EmailVerification = (props) => {
    const navigate = useNavigate();
    const { regData,verifyFunc,resendEmailFunc,eVerification,login } = props;
    let url = window.location.pathname
    let aCode = url.split("/");
    let code = typeof aCode[2] !== 'undefined' ? aCode[2] : regData.data > 0 ? regData.data : "";
    const [token, setToken] = useState(code);
    const [fired, setFired] = useState(false);
    const [icon, setIcon] = useState("");
    const [slog, setSlog] = useState("");

    useEffect(() => {
        setTimeout(() => {
            if(code.length === 0 )
                navigate("/");
        }, 10);
    }, []);

    useEffect(() => {
        if(login.isLogin)
            navigate("/");
    }, [login]);

    useEffect(() => {
        setTimeout(() => {
            if(eVerification.status){
                setIcon("success")
                setSlog("Success")
                setTimeout( () =>{
                    navigate("/");
                },7000)
            }else{
                setIcon("danger")
                setSlog("Fail")
            }
        }, 100);
    }, [eVerification]);

    const handleResend = () => {
        let objToken = {
            user_id: token
        };
        resendEmailFunc(objToken)
    }

    const handleFormSubmit = (data) => {
        setFired(false)
        let objToken = {
            token_id: token
        };
        let postData = Object.assign({}, data, objToken);
        verifyFunc(postData)
            .then(() =>{
                setToken(token)
                setFired(true)
            })
    }

    return(
        <>
        {/*<Header style="box-shadow-none"/>*/}
        <div className="page-content">
            <section className="style-1 map-wrapper1 overflow-hidden dashboard">
                <div className="row">
                    <div className="col-xl-3 "></div>
                    <div className="col-xl-6  col-sm-12">
                        <div className="form-wrapper style-1 pt-2 mt-5">
                            <div className="register-logo">
                                <Link to={"/"}><img src={logo} alt="" /></Link>
                            </div>
                            <h4 className="title m-a0 wow fadeInUp text-center mb-3" style={{color:'#FFF'}} data-wow-delay="1.6s">
                                Email Verification
                            </h4>

                            {
                                fired ?  <AlertBox message={eVerification.message} icon={icon} slog={slog}/> : ""
                            }

                            <div className="contact-area">
                                <Formik
                                    initialValues={{
                                        verification_code: "",
                                    }}
                                    validationSchema={validateSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            handleFormSubmit(values)
                                            setSubmitting(false);
                                        }, 400);
                                    }}

                                >
                                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                                            <div className="dzFormMsg"></div>
                                            <div className="row ">
                                                <div className="col-md-12">
                                                    <Input
                                                        name="verification_code"
                                                        id="verification_code"
                                                        placeholder="Enter Code"
                                                        errors={errors.verification_code}
                                                        value={values.verification_code}
                                                        handleChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-12 p-t20">
                                                    <button name="submit" type="submit" value="Login" className="btn btn-primary wow fadeInUp" data-wow-delay="2.3s">Verify</button>
                                                    <button onClick={handleResend} name="submit" type="button" value="Resend Code" className="btn btn-secondary wow fadeInUp m-l20" data-wow-delay="2.3s">Resend Code</button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 "></div>
                </div>
            </section>
        </div>
        {/*<Footer />*/}
        </>
    )

}

const initMapDispatchToProps = dispatch => ({
    verifyFunc: (data) => dispatch(verifyEmailAsync(data)),
    resendEmailFunc: (data) => dispatch(verifyEmailResendAsync(data))
})

const initMapStateToProps = state => ({
    regData: state.register,
    eVerification: state.emailVerification,
    login: state.login
})

export default connect(initMapStateToProps,initMapDispatchToProps)(EmailVerification);