import React,{useState} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import logo from "../assets/images/logo-dark.png";
import Input from '../components/Input'
import InputDropdown from '../components/dropdown'
import Password from '../components/Password'
import * as Yup from "yup";
import * as formik from 'formik';
import {connect} from "react-redux";
import {fetchCountriesAsync} from "../redux/action/fetchCountries.action";
import { useEffect } from "react";
import {registerAsync} from "../redux/action/register.action";
import AlertBox from "../components/Alert";

const Registration = (props) => {
    const { Formik } = formik;
    const registerSchema = Yup.object().shape({
        country_id: Yup.string()
            .required("Please select country"),
        first_name: Yup.string()
            .required("Please provide a first name")
            .max(100, "Provided name is too long"),
        last_name: Yup.string()
            .required("Please provide a last name")
            .max(100, "Provided name is too long"),
        email: Yup.string()
            .required("Please provide a email")
            .email('Please provide valid email'),
        user_password: Yup.string()
            .required("Please provide a password"),
        confirm_password: Yup.string()
            .required("Please provide a confirm password")
            .oneOf([Yup.ref('user_password'), null], 'Confirm Passwords must match with Password'),
    });
    const navigate = useNavigate();
    const { countriesFunc,countries,registerFunc, regData, login } = props;
    const [fired, setFired] = useState(false);
    const [icon, setIcon] = useState("");
    const [slog, setSlog] = useState("");
    let url = window.location.pathname
    let aCode = url.split("/");
    let code = typeof aCode[2] === 'undefined' ? "" : aCode[2];
    const [inviteCode, setinviteCode] = useState(code);

    useEffect(() => {
        setTimeout(() => {
            countriesFunc();
        }, 400);
    }, []);

    useEffect(() => {
        if(login.isLogin)
            navigate("/");
    }, [login]);

    useEffect(() => {
        if(regData.status){
            navigate("/email-verification");
            setIcon("success")
            setSlog("Success")
        }else{
            setIcon("danger")
            setSlog("Fail")
        }

    }, [regData]);

    const handleFormSubmit = (data) => {
        setFired(false)
        registerFunc(data)
            .then(()=>{
                setFired(true)
            })
    };

    return(
        <>
        {/*<Header style="box-shadow-none"/>*/}
        <div className="page-content ">
            <section className="style-1 map-wrapper1 overflow-hidden  dashboard">
                <div className="row">
                    <div className="col-xl-3 "></div>
                    <div className="col-xl-6  col-sm-12">
                        <div className="form-wrapper style-1 pt-2 mt-5">
                            <div className="register-logo">
                                <Link to={"/"}><img src={logo} alt="" /></Link>
                            </div>
                            <h5 className="title m-a0 wow fadeInUp text-center mb-3" style={{color:'#FFF'}} data-wow-delay="1.6s">Sign up your account</h5>

                            {
                                fired ?  <AlertBox message={regData.message} icon={icon} slog={slog}/> : ""
                            }

                            <div className="contact-area">
                                <Formik
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            handleFormSubmit(values);
                                            setinviteCode(values.invited_code)
                                            setSubmitting(false);
                                        }, 400);
                                    }}
                                    initialValues={{
                                        country_id: '',
                                        first_name: '',
                                        last_name: '',
                                        email: '',
                                        user_password: '',
                                        confirm_password: '',
                                        invited_code: inviteCode,
                                    }}
                                    validationSchema={registerSchema}
                                >
                               {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                <form className="dz-form dzForm style-1 contact-bx"  noValidate onSubmit={handleSubmit} >
                                    <div className="dzFormMsg"></div>
                                    <div className="row ">
                                        <div className="col-md-12">
                                            <InputDropdown
                                                name="country_id"
                                                id="country_id"
                                                placeholder="Select Country"
                                                selected_value=""
                                                errors={errors.country_id}
                                                countryList={countries}
                                                value={values.country_id}
                                                handleChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <Input name="first_name"
                                                   id="first_name"
                                                   placeholder="First Name"
                                                   errors={errors.first_name}
                                                   value={values.first_name}
                                                   handleChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <Input
                                                name="last_name"
                                                id="last_name"
                                                placeholder="Last Name"
                                                errors={errors.last_name}
                                                value={values.last_name}
                                                handleChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <Input
                                                name="email"
                                                id="email"
                                                placeholder="Email"
                                                errors={errors.email}
                                                value={values.email}
                                                handleChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <Password
                                                name="user_password"
                                                id="user_password"
                                                placeholder="Password"
                                                errors={errors.user_password}
                                                value={values.user_password}
                                                handleChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <Password
                                                name="confirm_password"
                                                id="confirm_password"
                                                placeholder="Confirm Password"
                                                errors={errors.confirm_password}
                                                value={values.confirm_password}
                                                handleChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <Input
                                                name="invited_code"
                                                id="invited_code"
                                                placeholder="Inviter code"
                                                errors={errors.invited_code}
                                                value={values.invited_code}
                                                handleChange={handleChange}
                                            />
                                        </div>

                                        <div className="col-md-12 p-t20">
                                            <button name="submit" type="submit" value="Submit" className="btn btn-primary wow fadeInUp" data-wow-delay="2.3s">Register</button>
                                        </div>
                                    </div>
                                </form>
                               )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 "></div>
                </div>
            </section>
        </div>
        {/*<Footer />*/}
        </>
    )
}

const MapDispatchToProps = dispatch => ({
    countriesFunc: () => dispatch(fetchCountriesAsync()),
    registerFunc: (data) => dispatch(registerAsync(data))
})

const mapStateToProps = state => ({
    countries: state.countries.data,
    regData: state.register,
    login: state.login
})

export default connect(mapStateToProps,MapDispatchToProps) (Registration);