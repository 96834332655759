export const emailVerificationReducer = (state = {message:'',status:false}, action) => {
    switch (action.type){
        case 'VERIFY_EMAIL_START':
            return state;
        case 'VERIFY_EMAIL_ERROR':
            return {...state, message:action.payload, status:false };
        case 'VERIFY_EMAIL_SUCCESS':
            return {...state, message:action.payload, status:true };
        default:
            return state;
    }
}