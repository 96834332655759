import React from 'react';

const TextArea = ({name,placeholder,id, errors, value, handleChange}) => {

    return (
        <div className="input-group wow fadeInUp" data-wow-delay="1.8s">
            <textarea
                name={name}
                id={name}
                rows="5"
                className="form-control"
                placeholder={placeholder}
                onChange={handleChange}
                value={value}
            >

            </textarea>
            <div
                id={`${id}-error`}
                className="invalid-feedback animated fadeInUp p-0"
                style={{ display: "block" }}
            >
                {errors && errors}
            </div>
        </div>

    )

}

export default TextArea



