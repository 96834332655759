const init = {
    data: {},
    message: '',
    status: false
}
export const fetchRecentActivityReducer = (state=init, action) => {
    switch(action.type){
        case 'FETCH_RECENT_ACTIVITY_START':
            return state;
        case 'FETCH_RECENT_ACTIVITY_SUCCESS':
            return {...state, data:action.payload, status: true};
        case 'FETCH_RECENT_ACTIVITY_ERROR':
            return {...state, message: action.payload};
        default:
            return state;
    }
};