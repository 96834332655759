const init = {
    isLogin:false,
    message:'',
    data:{}
}

export const loginReducer = (state = init, action) => {
    switch (action.type){
        case 'LOGIN_START':
            return state;
        case 'LOGIN_FAIL':
            return {...state, message:action.payload, isLogin:false};
        case 'LOGIN_SUCCESS':
            return {...state, message:'', isLogin:true, data:action.payload.data };
        default:
            return state;
    }
};