import React from 'react';
import {Link} from 'react-router-dom';

import IMAGES from '../constants/theme';
import bg from '../assets/images/background/bg2.jpg';
import shape from '../assets/images/home-banner/shape1.svg';

function PageTitle({parentTitle, activeTitle}){
	return(
		<>
			<div className="dz-bnr-inr dz-bnr-inr-sm text-center position-relative" style={{backgroundImage:`url(${bg})` }}>
				<div className="container">
					<div className="dz-bnr-inr-entry">
						<h1>{activeTitle}</h1>							
						{/*<nav className="breadcrumb-row m-t15">*/}
							{/*<ul className="breadcrumb">*/}
								{/*<li className="breadcrumb-item"><Link to={"/"}>{parentTitle}</Link></li>*/}
								{/*<li className="breadcrumb-item active" aria-current="page">{activeTitle}</li>*/}
							{/*</ul>*/}
						{/*</nav>							*/}
					</div>
				</div>
				<img className="shape1" src={shape} alt="" />
			</div>
		</>
	)
} 
export default PageTitle;