import axios from 'axios';

const csrf =  document.querySelector('meta[name="csrf-token"]').content

export default axios.create({
    headers: {
        post: {        // can be common or any other method
            header: csrf
        }
    },
    // baseURL: `http://localhost:8000/api/`
    baseURL: `https://futurefx.com.au/api/`
});

